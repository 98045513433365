<template lang="pug">
  .rate-plan-table
    table.container-fluid
      thead
        tr
          th.sticky-col.status
            span {{ $t("company_system.rate_plan_settings.rate_plan.status") }}
          th.sticky-col.channel
            span {{ $t("company_system.rate_plan_settings.rate_plan.channel") }}
          th.sticky-col.plan-name.sortable(@click="handleSorting('name')")
            span {{ $t("company_system.rate_plan_settings.rate_plan.plan_name") }}
            FaIcon.icon(:icon="sortingIcon('name')")
          th.sale-start.sortable(@click="handleSorting('sale_start')")
            span {{ $t("company_system.rate_plan_settings.rate_plan.sale_start") }}
            FaIcon.icon(:icon="sortingIcon('sale_start')")
          th.sale-end.sortable(@click="handleSorting('sale_end')")
            span {{ $t("company_system.rate_plan_settings.rate_plan.sale_end") }}
            FaIcon.icon(:icon="sortingIcon('sale_end')")
          th.post-start.sortable(@click="handleSorting('post_start')")
            span {{ $t("company_system.rate_plan_settings.rate_plan.post_start") }}
            FaIcon.icon(:icon="sortingIcon('post_start')")
          th.post-end.sortable(@click="handleSorting('post_end')")
            span {{ $t("company_system.rate_plan_settings.rate_plan.post_end") }}
            FaIcon.icon(:icon="sortingIcon('post_end')")
          th.shop-name.sortable(@click="handleSorting('shop_names')")
            span {{ $t("company_system.rate_plan_settings.rate_plan.shop_name") }}
            FaIcon.icon(:icon="sortingIcon('shop_names')")
          th.car-class.sortable(@click="handleSorting('car_class_names')")
            span {{ $t("company_system.rate_plan_settings.rate_plan.car_class") }}
            FaIcon.icon(:icon="sortingIcon('car_class_names')")
          th.last-update.sortable(@click="handleSorting('updated_at')")
            span {{ $t("company_system.rate_plan_settings.rate_plan.last_update") }}
            FaIcon.icon(:icon="sortingIcon('updated_at')")
          th.actions
      tbody
        template(v-for="item in items")
          tr(
            @click="$emit('select-row', item)"
          )
            td.sticky-col.status
              .status-wrapper
                .status-tooltip(
                  v-if="isTooltipShown(item)"
                )
                  FaIcon.status-tooltip-icon(
                    :id="`${item.id}-plan-tooltip`"
                    icon="exclamation-triangle"
                  )
                  BTooltip(
                    :target="`${item.id}-plan-tooltip`"
                    triggers="hover"
                    placement="bottom"
                  )
                    .tooltip-row(v-for="(published, otaId) in item.published_by_ota")
                      span(
                        v-if="otasById[Number(otaId)]"
                      ) {{ tooltipRowText(otaId, published) }}
                span {{ translatedReleaseStatus(item.status) }}
            td.sticky-col.channel {{ extractOtaNames(item) }}
            td.sticky-col.plan-name {{ item.name }}
            td {{ item.sale_start }}
            td {{ item.sale_end }}
            td {{ item.post_start }}
            td {{ item.post_end }}
            td {{ item.shop_names }}
            td
              AppTextExpandable(
                :key="item.id"
                :text="item.car_class_names"
              )
            td {{ item.updated_at }}
            td.sticky-col.remove-action
              AppIconButton.action-button(
                :disabled="!hasEditPermission"
                icon="trash-alt"
                @click.stop="$emit('remove-row', item)"
              )
</template>

<script>
  // misc
  import { reduce, values, some } from "lodash-es"

  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    components: {
      AppTextExpandable: () => import("@/components/elements/AppTextExpandable"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [withSorting],

    props: {
      items: {
        type: Array,
        default: () => new Array()
      },
      otas: {
        type: Array,
        default: () => new Array()
      },
      hasEditPermission: {
        type: Boolean,
        default: true
      },

      sortingData: Object
    },

    computed: {
      otasById() {
        return reduce(
          this.otas,
          (obj, ota) => {
            obj[ota.id] = ota

            return obj
          },
          {}
        )
      }
    },

    methods: {
      extractOtaNames({ ota_ids }) {
        return reduce(
          ota_ids,
          (arr, otaId) => {
            const ota = this.otasById[otaId]
            if (ota) {
              arr.push(ota.name)
            }
            return arr
          },
          []
        ).join(", ")
      },

      isTooltipShown(item) {
        return some(values(item.published_by_ota), published => !published) && item.status === "published"
      },

      tooltipRowText(otaId, published) {
        return `${this.otasById[Number(otaId)].name}: ${published ? "ON" : "OFF"}`
      },

      translatedReleaseStatus(status) {
        return this.$t(`ota_management.plan_list.status.${status}`)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .rate-plan-table
    +listing-container(188px)
    margin-top: 30px
    margin-left: 10px

    table
      +matchings-table-header-sticky

      width: 100%
      overflow-wrap: break-word
      position: relative
      overflow: auto

      thead
        th
          +cell-width(200px)
          &.sticky-col
            +sticky-col
            top: 0
            z-index: 11

          &.status
            +cell-width(86px)
            left: 0

          &.channel
            +cell-width(100px)
            left: 96px

          &.plan-name
            +cell-width(240px)
            left: 196px

          &.actions
            +cell-width(40px)
      tbody
        tr
          cursor: pointer
          border-bottom: 1px solid $border-element-color

          td
            +cell-width(200px)
            +matchings-item-td
            border-bottom: none
            &.status
              +cell-width(96px)
              left:  0

              .status-wrapper
                display: flex
                align-items: center

                .status-tooltip
                  &-icon
                    margin-left: 0
                    margin-right: 5px
                    color: $default-yellow

            &.channel
              +cell-width(100px)
              left: 96px

            &.plan-name
              +cell-width(240px)
              left: 196px

            &.remove-action
              +cell-width(40px)
              right: 0
              padding: 0 0 0 15px
              color: $default-purple

              .action-button
                cursor: pointer
                +icon-button($default-purple)
</style>
